/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { PropsWithChildren } from 'react';

import { InventoryContext } from '@/contexts/InventoryContext';
import { LayoutProvider } from '@/contexts/LayoutContext';
import { InventoryProvider } from '@lamesarv-sdk/inventory';
import { IFilterBase, InventoryField } from '@lamesarv-sdk/types';

interface LayoutProvidersProps {
  customEquipmentFilters: IFilterBase;
  noPriceButtonContent?: string;
}

export const LayoutProviders = (props: PropsWithChildren<LayoutProvidersProps>) => (
  <LayoutProvider noPriceButtonContent={props.noPriceButtonContent}>
    <InventoryProvider
      clientContext={InventoryContext as any}
      inventoryFiltersCustom={{
        [InventoryField.equipment]: props.customEquipmentFilters,
      }}
    >
      {props.children as any}
    </InventoryProvider>
  </LayoutProvider>
);
